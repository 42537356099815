import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  useColorModeValue,
  IconButton,
  Spinner,
  useToast,
  Card,
  useDisclosure,
  TableContainer,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { MdAdd, MdEdit, MdDelete } from "react-icons/md";
import { SearchIcon } from "@chakra-ui/icons";
import AddPincode from "./AddPincode";
import EditPincode from "./EditPincode";
import DeletePincode from "./DeletePincode";
import { getPincodesApi } from "networks";
import { updatePincodeApi } from "networks";
import { deletePincodeApi } from "networks";
import { addPincodeApi } from "networks";
import moment from "moment"; // for date formatting

const PincodeManagement = () => {
  const [pincodes, setPincodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editPincodeData, setEditPincodeData] = useState(null);
  const [deletePincodeData, setDeletePincodeData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const addPincodeModal = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    fetchPincodesData();
  }, []);

  // Fetch all pincodes from the server
  const fetchPincodesData = async () => {
    setLoading(true);
    try {
      const response = await getPincodesApi(); // Call API
      setPincodes(response.data.data); // Assuming API returns data in response.data
    } catch (error) {
      toast({
        title: "Error fetching pincodes.",
        description: "An error occurred while fetching pincodes.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Add a new pincode
  const handleAddPincode = async (newPincode) => {
    try {
      await addPincodeApi(newPincode); // Call API to add pincode
      fetchPincodesData();
      toast({
        title: "Pincode added.",
        description: "The pincode has been added successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      addPincodeModal.onClose();
    } catch (error) {
      toast({
        title: "Error adding pincode.",
        description: "An error occurred while adding the pincode.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Update an existing pincode
  const handleUpdatePincode = async (updatedPincode) => {
    try {
      await updatePincodeApi(updatedPincode); // Call API to update pincode
      fetchPincodesData();
      toast({
        title: "Pincode updated.",
        description: "The pincode has been updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setEditPincodeData(null);
    } catch (error) {
      toast({
        title: "Error updating pincode.",
        description: "An error occurred while updating the pincode.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Delete a pincode
  const handleDeletePincode = async (id) => {
    try {
      await deletePincodeApi(id); // Call API to delete pincode
      fetchPincodesData();
      toast({
        title: "Pincode deleted.",
        description: "The pincode has been deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setDeletePincodeData(null);
    } catch (error) {
      toast({
        title: "Error deleting pincode.",
        description: "An error occurred while deleting the pincode.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditPincode = (pincode) => {
    setEditPincodeData(pincode);
  };

  const handleDeletePincodeModal = (pincode) => {
    setDeletePincodeData(pincode);
  };

  const filteredPincodes = pincodes.filter((pincode) =>
    pincode.pincode.toLowerCase().includes(searchTerm.toLowerCase()) ||
    pincode.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY="auto">
      <Flex flexDirection="column">
        <Flex
          mt={{ base: "20px", md: "45px" }}
          mb="20px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color={textColor} fontSize={{ base: "lg", md: "2xl" }} ms="24px" fontWeight="700">
            Pincode Management
          </Text>
          <Button
            leftIcon={<MdAdd />}
            bg="#FFFFFF"
            color="#333"
            _hover={{ bg: "#D3D3D3" }}
            onClick={addPincodeModal.onOpen}
          >
            Add Pincode
          </Button>
        </Flex>

        {/* Search Bar */}
        <Flex justify="flex-end" mt={4} mr={4}>
          <InputGroup>
            <Input
              type="text"
              placeholder="Search by pincode or status..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              backgroundColor="white"
              size="md"
            />
            <InputRightElement>
              <IconButton
                aria-label="Search"
                icon={<SearchIcon />}
                onClick={() => setSearchTerm('')}
                variant="ghost"
                size="md"
              />
            </InputRightElement>
          </InputGroup>
        </Flex>

        {/* Pincode Table */}
        <Flex justifyContent="center" mt={10}>
          {loading ? (
            <Flex justify="center" align="center" height="10vh">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Card width="95%" borderRadius={20}>
              <TableContainer>
                <Table variant="simple" size="md">
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Pincode</Th>
                      <Th>City</Th>
                      <Th>Status</Th>
                      <Th>Created At</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredPincodes.map((pincode) => (
                      <Tr key={pincode.id}>
                        <Td>{pincode.id}</Td>
                        <Td>{pincode.pincode}</Td> {/* Updated from code to pincode */}
                        <Td>{pincode.city}</Td> {/* Added City */}
                        <Td color={pincode.status === "Active" ? "green.500" : "red.500"}>
                          {pincode.status}
                        </Td>
                        <Td>{moment(pincode.created_at).format("YYYY-MM-DD HH:mm")}</Td> {/* Formatted created_at */}
                        <Td>
                          <IconButton
                            icon={<MdEdit />}
                            onClick={() => handleEditPincode(pincode)}
                            aria-label="Edit Pincode"
                            mr={2}
                            colorScheme="teal"
                            size="sm"
                          />
                          <IconButton
                            icon={<MdDelete />}
                            onClick={() => handleDeletePincodeModal(pincode)}
                            aria-label="Delete Pincode"
                            colorScheme="red"
                            size="sm"
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </Flex>
      </Flex>

      {/* AddPincode modal */}
      <AddPincode
        isOpen={addPincodeModal.isOpen}
        onClose={addPincodeModal.onClose}
        addNewPincode={handleAddPincode}
      />

      {/* EditPincode modal */}
      {editPincodeData && (
        <EditPincode
          isOpen={!!editPincodeData}
          onClose={() => setEditPincodeData(null)}
          updatePincodeProp={editPincodeData}
          updatePincode={handleUpdatePincode}
        />
      )}

      {/* DeletePincode modal */}
      {deletePincodeData && (
        <DeletePincode
          isOpen={!!deletePincodeData}
          onClose={() => setDeletePincodeData(null)}
          deletePincodeProp={deletePincodeData}
          deletePincode={handleDeletePincode}
        />
      )}
    </Box>
  );
};

export default PincodeManagement;
