import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from '@chakra-ui/react';

const DeletePaymentGateway = ({ isOpen, onClose, deleteGatewayProp, deleteGateway }) => {
  const handleDelete = () => {
    deleteGateway(deleteGatewayProp.id); // Call the deleteGateway function with the gateway ID
    onClose(); // Close the modal after deletion
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Payment Gateway</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Are you sure you want to delete the payment gateway with key "{deleteGatewayProp.gateway_key}"?</Text>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={handleDelete}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeletePaymentGateway;
