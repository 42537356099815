import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  useColorModeValue,
  Spinner,
  useToast,
  Card,
  TableContainer,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { getTransactionsApi } from "networks"; // API call to fetch transactions
import moment from "moment"; // For date formatting

const TransactionManagement = () => {
  const [transactions, setTransactions] = useState([]); // Holds transaction data
  const [loading, setLoading] = useState(true); // Manages loading state
  const [searchTerm, setSearchTerm] = useState(""); // Search input state
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const toast = useToast();

  useEffect(() => {
    fetchTransactionsData(); // Fetch transaction data on component mount
  }, []);

  // Fetch all transactions from the server
  const fetchTransactionsData = async () => {
    setLoading(true); // Start loading state
    try {
      const response = await getTransactionsApi(); // API call to fetch transactions
      setTransactions(response.data.data); // Set transactions state with fetched data
    } catch (error) {
      // Error handling
      toast({
        title: "Error fetching transactions.",
        description: "An error occurred while fetching transactions.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false); // End loading state
    }
  };

  // Filter transactions based on search term
  const filteredTransactions = transactions.filter(
    (transaction) =>
      transaction.transaction_id.toString().includes(searchTerm.toLowerCase()) ||
      transaction.user_id.toString().includes(searchTerm.toLowerCase()) ||
      transaction.transaction_status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY="auto">
      <Flex flexDirection="column">
        <Flex
          mt={{ base: "20px", md: "45px" }}
          mb="20px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color={textColor} fontSize={{ base: "lg", md: "2xl" }} ms="24px" fontWeight="700">
            Transaction Management
          </Text>
        </Flex>

        {/* Search Bar */}
        <Flex justify="flex-end" mt={4} mr={4}>
          <InputGroup>
            <Input
              type="text"
              placeholder="Search by transaction ID, user ID or status..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              backgroundColor="white"
              size="md"
            />
            <InputRightElement>
              <SearchIcon />
            </InputRightElement>
          </InputGroup>
        </Flex>

        {/* Transaction Table */}
        <Flex justifyContent="center" mt={10}>
          {loading ? (
            <Flex justify="center" align="center" height="10vh">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Card width="95%" borderRadius={20} >
              {/* Wrap the TableContainer with Box for horizontal scroll */}
              <Box overflowX="auto">
                <TableContainer>
                  <Table variant="simple" size="md">
                    <Thead>
                      <Tr>
                        <Th>Transaction ID</Th>
                        <Th>User ID</Th>
                        <Th>Payment ID</Th>
                        <Th>Order ID</Th>
                        <Th>Amount</Th>
                        <Th>Transaction Type</Th>
                        <Th>Transaction Status</Th>
                        <Th>Date</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredTransactions.map((transaction) => (
                        <Tr key={transaction.transaction_id}>
                          <Td>{transaction.transaction_id}</Td>
                          <Td>{transaction.user_id}</Td>
                          <Td>{transaction.payment_id}</Td>
                          <Td>{transaction.order_id}</Td>
                          <Td>{transaction.amount}</Td>
                          <Td>{transaction.transaction_type}</Td>
                          <Td color={transaction.transaction_status === "Success" ? "green.500" : "red.500"}>
                            {transaction.transaction_status}
                          </Td>
                          <Td>{moment(transaction.modified_at).format("YYYY-MM-DD HH:mm")}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Card>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default TransactionManagement;
