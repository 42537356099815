import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Select,
  Button,
  useToast,
} from "@chakra-ui/react";
import { updateOrderApi } from "networks"; // Adjust the import as necessary

const EditOrder = ({ isOpen, onClose, orderData, refreshOrders }) => {
  const toast = useToast();
  const [orderStatus, setOrderStatus] = useState(orderData.status); // Only track status

  useEffect(() => {
    if (orderData) {
      setOrderStatus(orderData.status); // Set initial status from orderData
    }
  }, [orderData]);

  const handleChange = (e) => {
    const { value } = e.target;
    setOrderStatus(value); // Update the status
  };

  const handleSubmit = async () => {
    try {
      const updatedOrder = { ...orderData, status: orderStatus }; // Create updated order object
      await updateOrderApi(updatedOrder); // Call API to update order
      toast({
        title: "Order Updated",
        description: "The order status has been updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      refreshOrders(); // Refresh the orders list
      onClose(); // Close the modal
    } catch (error) {
      toast({
        title: "Error Updating Order",
        description: "An error occurred while updating the order.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Order Status</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={orderStatus}
              onChange={handleChange}
            >
              <option value="Pending">Pending</option>
              <option value="Accepted">Accepted</option>
              <option value="Delivering">Delivering</option>
              <option value="Delivered">Delivered</option>
              <option value="Cancelled">Cancelled</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditOrder;
