import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Select,
  Input,
  Button,
  useToast,
} from '@chakra-ui/react';

const EditSubscription = ({ isOpen, onClose, subscriptionData, updateSubscription, selectedDate }) => {
  const [subscription, setSubscription] = useState({
    subscription_id: '',
    status: '',
    reason: '', // State for reason
  });

  const toast = useToast();

  useEffect(() => {
    if (subscriptionData) {
      setSubscription(subscriptionData);
    }
  }, [subscriptionData]);

  const handleStatusChange = (value) => {
    setSubscription({ ...subscription, status: value });
    if (value !== 'Cancelled') {
      setSubscription((prev) => ({ ...prev, reason: '' })); // Clear reason if status is not cancelled
    }
  };

  const handleSubmit = () => {
    if (subscription.status) {
      if (subscription.status === 'Cancelled' && !subscription.reason) {
        toast({
          title: 'Error.',
          description: 'Please provide a reason for cancellation.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      updateSubscription({ ...subscription, date: selectedDate }); // Include the selected date
      onClose();
    } else {
      toast({
        title: 'Error.',
        description: 'Please select a status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Subscription Status</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={subscription.status}
              onChange={(e) => handleStatusChange(e.target.value)}
              placeholder="Select status"
            >
              <option value="Pending">Pending</option>
              <option value="Accepted">Accepted</option>
              <option value="Delivering">Delivering</option>
              <option value="Delivered">Delivered</option>
              <option value="Cancelled">Cancelled</option>
            </Select>
          </FormControl>

          {/* Show reason input if status is cancelled */}
          {subscription.status === 'Cancelled' && (
            <FormControl mb={3}>
              <FormLabel>Reason for Cancellation</FormLabel>
              <Input
                type="text"
                value={subscription.reason}
                onChange={(e) => setSubscription({ ...subscription, reason: e.target.value })}
                placeholder="Enter cancellation reason"
              />
            </FormControl>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditSubscription;
