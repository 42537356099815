import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { MdNotifications } from "react-icons/md";
import { sendNotificationApi } from "networks";

const NotificationSender = ({ users }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [notificationMessage, setNotificationMessage] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // Handle message input change
  const handleNotificationChange = (e) => setNotificationMessage(e.target.value);

  // Handle individual user selection
  const handleUserSelect = (userId) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };

  // Handle select all users
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedUsers(selectAll ? [] : users.map((user) => user.id));
  };

  // Handle send notification action
  const handleSendNotification = async () => {
   
  
    const payload = {
      message: notificationMessage,
      user_ids: selectedUsers,
    };
  
    try {
      const response = await sendNotificationApi(payload)
  
      const result = await response.json();
      if (response.ok) {
        console.log("Notification sent successfully:", result);
      } else {
        console.error("Failed to send notification:", result.message);
      }
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  
    onClose(); // Close the modal after sending
  };

  return (
    <Box>
      <Button leftIcon={<MdNotifications />} colorScheme="blue" onClick={onOpen}>
        Send Notification
      </Button>

      {/* Notification Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Notification</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Notification Message Input */}
            <Input
              placeholder="Enter notification message"
              value={notificationMessage}
              onChange={handleNotificationChange}
              mb={3}
            />

            {/* Select All Checkbox */}
            <Checkbox isChecked={selectAll} onChange={handleSelectAll} mb={3}>
              Select All
            </Checkbox>

            {/* User List with Checkboxes */}
            <Box maxHeight="200px" overflowY="scroll">
              {users.map((user) => (
                <Flex key={user.id} align="center" mb={2}>
                  <Checkbox
                    isChecked={selectedUsers.includes(user.id)}
                    onChange={() => handleUserSelect(user.id)}
                  >
                    {user.name}
                  </Checkbox>
                </Flex>
              ))}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSendNotification}>
              Send Notification
            </Button>
            <Button variant="ghost" onClick={onClose} ml={3}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default NotificationSender;
