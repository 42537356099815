import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  useToast,
} from '@chakra-ui/react';

const AddPincode = ({ isOpen, onClose, addNewPincode }) => {
  const [newPincode, setNewPincode] = useState({
    code: '',
    status: '',
    city: '', // Add city field
  });

  const toast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPincode({ ...newPincode, [name]: value });
  };

  const handleSubmit = () => {
    if (newPincode.code && newPincode.status && newPincode.city) {
      addNewPincode(newPincode);
      setNewPincode({ code: '', status: '', city: '' }); // Reset city as well
      onClose();
    } else {
      toast({
        title: 'Error.',
        description: 'Please fill out all fields.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Pincode</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="code" mb={3}>
            <FormLabel>Pincode</FormLabel>
            <Input
              type="text"
              name="code"
              value={newPincode.code}
              onChange={handleInputChange}
              placeholder="Enter pincode"
            />
          </FormControl>

          <FormControl id="status" mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={newPincode.status}
              onChange={handleInputChange}
              placeholder="Select status"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Select>
          </FormControl>

          {/* City Dropdown */}
          <FormControl id="city" mb={3}>
            <FormLabel>City</FormLabel>
            <Select
              name="city"
              value={newPincode.city}
              onChange={handleInputChange}
              placeholder="Select city"
            >
              <option value="Bengaluru">Bengaluru</option>
              <option value="Mysuru">Mysuru</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddPincode;
