import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  useToast,
} from '@chakra-ui/react';

const EditPaymentGateway = ({ isOpen, onClose, updateGatewayProp, updateGateway }) => {
  const [gateway, setGateway] = useState({
    id: '',
    gateway_key: '',
    secret_key: '',
    status: '',
  });

  const toast = useToast();

  useEffect(() => {
    if (updateGatewayProp) {
      setGateway({
        id: updateGatewayProp.id || '',
        gateway_key: updateGatewayProp.gateway_key || '',
        secret_key: updateGatewayProp.secret_key || '',
        status: updateGatewayProp.status || '',
      });
    }
  }, [updateGatewayProp]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGateway({ ...gateway, [name]: value });
  };

  const handleSubmit = () => {
    if (gateway.gateway_key && gateway.secret_key && gateway.status) {
      updateGateway(gateway);
      onClose();
    } else {
      toast({
        title: 'Error.',
        description: 'Please fill out all fields.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(null)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Payment Gateway</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="gateway_key" mb={3}>
            <FormLabel>Gateway Key</FormLabel>
            <Input
              type="text"
              name="gateway_key"
              value={gateway.gateway_key}
              onChange={handleInputChange}
              placeholder="Enter gateway key"
            />
          </FormControl>

          <FormControl id="secret_key" mb={3}>
            <FormLabel>Secret Key</FormLabel>
            <Input
              type="password"
              name="secret_key"
              value={gateway.secret_key}
              onChange={handleInputChange}
              placeholder="Enter secret key"
            />
          </FormControl>

          <FormControl id="status" mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={gateway.status}
              onChange={handleInputChange}
              placeholder="Select status"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={() => onClose(null)}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditPaymentGateway;
