import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  useToast,
} from '@chakra-ui/react';

const AddPaymentGateway = ({ isOpen, onClose, addNewGateway }) => {
  const [newGateway, setNewGateway] = useState({
    gateway_key: '',
    secret_key: '',
    status: '',
  });

  const toast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGateway({ ...newGateway, [name]: value });
  };

  const handleSubmit = () => {
    if (newGateway.gateway_key && newGateway.secret_key && newGateway.status) {
      addNewGateway(newGateway);
      setNewGateway({ gateway_key: '', secret_key: '', status: '' }); // Reset form
      onClose();
    } else {
      toast({
        title: 'Error.',
        description: 'Please fill out all fields.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Payment Gateway</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="gateway_key" mb={3}>
            <FormLabel>Gateway Key</FormLabel>
            <Input
              type="text"
              name="gateway_key"
              value={newGateway.gateway_key}
              onChange={handleInputChange}
              placeholder="Enter gateway key"
            />
          </FormControl>

          <FormControl id="secret_key" mb={3}>
            <FormLabel>Secret Key</FormLabel>
            <Input
              type="password"
              name="secret_key"
              value={newGateway.secret_key}
              onChange={handleInputChange}
              placeholder="Enter secret key"
            />
          </FormControl>

          <FormControl id="status" mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={newGateway.status}
              onChange={handleInputChange}
              placeholder="Select status"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddPaymentGateway;
