import {
  Box,
  Flex,
  HStack,
  Text,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import React from "react";
import SidebarRoutes from "Sidebar/Sidebar_routes";

export function SidebarLinks() {
  let location = useLocation();
  let activeColor = useColorModeValue("black", "white");
  let inactiveColor = useColorModeValue("white", "secondaryGray.600");
  let activeIcon = useColorModeValue("blue", "white");
  let textColor = useColorModeValue("black", "white");
  let activeSubTabBg = useColorModeValue("gray.100", "gray.700"); // Background for active sub-tab

  const routes = SidebarRoutes;

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const activeChildRoute = (childPath) => {
    return location.pathname === childPath;
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      // If the route has children, create an accordion for it
      if (route.children && route.children.length > 0) {
        return (
          <Accordion allowMultiple key={index}>
            <AccordionItem border="none">
              <h2>
                <AccordionButton
                  _expanded={{ bg: inactiveColor, color: textColor }}
                  _hover={{ bg: "gray.100" }}
                  paddingLeft={0}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeIcon
                        : textColor
                    }
                  >
                    {route.icon && (
                      <Box as="span" mr="12px">
                        {route.icon}
                      </Box>
                    )}
                    {route.name}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {route.children.map((childRoute, childIndex) => (
                  <NavLink
                    key={childIndex}
                    to={childRoute.path}
                  >
                    <Text
                      fontSize="sm"
                      fontWeight="normal"
                      ml={8}
                      py={1}
                      color={
                        activeChildRoute(childRoute.path) ? activeColor : "gray.500"
                      }
                      bg={activeChildRoute(childRoute.path) ? activeSubTabBg : "transparent"}
                      borderRadius="md"
                      _hover={{ color: activeIcon }}
                      px={2} // Adding padding for better highlight visibility
                    >
                      {childRoute.name}
                    </Text>
                  </NavLink>
                ))}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        );
      }

      // For routes without children, just create a NavLink
      return (
        <NavLink key={index} to={route.path}>
          <HStack
            py="5px"
            spacing="10px"
            _hover={{ bg: "gray.100", cursor: "pointer" }}
          >
            <Flex alignItems="flex-start">
              {route.icon && (
                <Box
                  color={
                    activeRoute(route.path.toLowerCase()) ? activeIcon : textColor
                  }
                  me="10px"
                >
                  {route.icon}
                </Box>
              )}
              <Text
                color={
                  activeRoute(route.path.toLowerCase()) ? activeColor : textColor
                }
                fontWeight={
                  activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                }
              >
                {route.name}
              </Text>
            </Flex>
          </HStack>
        </NavLink>
      );
    });
  };

  return <Box>{createLinks(routes)}</Box>;
}

export default SidebarLinks;
