import React, { Suspense, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "Login/login";
import filteredRoutes from "Sidebar/Sidebar_routes";
import Dashboard from "Dashboard/Dashboard";
import OrderManagement from "OrderManagement/OrderManagement";
import BannerManagement from "Banners/BannerManagement";
import CategoryManagement from "Categories/CategoryManagement";
import ProductManagement from "Products/ProductManagement";
import CustomerManagement from "Customers/CustomerManagement";
import SubscriptionManagement from "Subscriptions/SubscriptionManagement";
import PincodeManagement from "Pincode/PincodeManagement";
import PaymentGatewayManagement from "PaymentGatewayManagement/PaymentGatewayManagement";
import TransactionsScreen from "TransactionManagement/TransactionManagement";

const LazyLoad = () => {
  useEffect(() => {
    // Your useEffect logic here
  }, []);

  const isLoggedIn = sessionStorage.getItem("login") === "true";
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {/* Conditional rendering based on isLoggedIn */}
        {isLoggedIn ? (
          // Render the first route from filteredRoutes if isLoggedIn is true
          filteredRoutes.length > 0 && (
            <Route
              path="/"
              element={<Navigate to={filteredRoutes[0].path} replace />}
            />
          )
        ) : (
          <Route path="/" element={<Login />} />
        )}

        {filteredRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={getElementForRoute(route)}
          />
        ))}
      </Routes>
    </Suspense>
  );
};

// Function to get the component for each route
const getElementForRoute = (route) => {
  switch (route.path) {
    case "/dashboard":
      return <Dashboard />;
    case "/ordermanagement":
      return <OrderManagement />;
    case "/banners":
      return <BannerManagement />;
    case "/categories":
        return <CategoryManagement />;
    case "/products":
      return <ProductManagement />;
    case "/customers":
        return <CustomerManagement />;
    case "/subscriptions":
      return <SubscriptionManagement />;
    case "/pincode":
      return <PincodeManagement />;
    case "/paymentgateway":
      return <PaymentGatewayManagement />;
    case "/transactions":
      return <TransactionsScreen />;
    default:
      return null;
  }
};

export default LazyLoad;
